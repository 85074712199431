/* Services */

#services {
  background-color: var(--color-light);
  position: relative;
}

#services:before {
  content: '';
  position: absolute;
  height: 50px;
  width: 100%;
  background-color: var(--color-light);
  border-radius: 50px 50px 0 0;
  top: -50px;
  left: 0;
}

#services ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 0;
}

#services .web li{
  background-color: var(--color-teal);
}

#services .skills li{
  background-color: var(--color-blue);
}

#services .other li{
  background-color: var(--color-red);
}

#services li {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: .4em 2em .2em;
  color: var(--color-light);
  font-size: 1.2em;
  transition: all .2s ease-in-out;
  flex-basis: fit-content;
}

#services li:hover {
  flex-grow: 1;
}
