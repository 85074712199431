/* About */
#about {
  border-radius: 50px 50px 0 0;
  background-color: var(--color-yellow);
  color: var(--color-dark);
  flex-direction: row;
  position: relative;
  transform: translateY(-50px);
}

#about:after {
  content: '';
  position: absolute;
  height: 50px;
  width: 100%;
  background-color: var(--color-yellow);
  border-radius: 50px 50px 0 0;
  bottom: -50px;
  left: 0;
}

#about .media {
  display: flex;
  justify-content: center;
}

#about .media img {
  max-width: 80%;
  max-height: 80%;
}
