/* Intro */

#intro {
  height: 100vh;
  position: relative;
  background-color: var(--color-yellow);
  color: var(--color-dark)
}

#intro h1 {
  position: absolute;
  padding-bottom: 1em;
}

@keyframes band{
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(calc(-1903px));
  }
  100% {
    transform: translateX(0);
  }
}

#intro {
  overflow: hidden;
}

#intro .emoji{
  color: var(--color-red);
  width: 100%;
  animation: band 180s linear infinite;
  white-space: nowrap;
  padding-bottom: .5em;
}
