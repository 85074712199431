#loader{
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color-red);
  color: var(--color-yellow);
  fill: var(--color-yellow);
  width: 100vw;
  height: 100vh;
  z-index: 999;
  transition: opacity 1s ease;
}

#loader .loader-content{
  animation: spin-emoji 2s infinite ease-in-out;
}

@keyframes spin-emoji {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

#loader.loaded{
  opacity: 0;
}
