/* Viewer */
dialog {
  background: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  height: 100%;
  width: 100%;
  flex: 1 1 100%;
  max-width: 100vw;
  max-height: 100vh;
  resize: none;
}

dialog::backdrop {
  height: 100%;
  overflow: hidden;
  max-height: 100vh;
}

#viewer,
#design-viewer{
  position: fixed;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  color: var(--color-dark);
  height: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  transform: translateY(100vh);
  opacity: 0;
  -webkit-transition: transform 0.5s ease-in-out, opacity 0.4s ease-in;
  -moz-transition: transform 0.5s ease-in-out, opacity 0.4s ease-in;
  -o-transition: transform 0.5s ease-in-out, opacity 0.4s ease-in;
  transition: transform 0.5s ease-in-out, opacity 0.4s ease-in;
  cursor: default;
}

#viewer.open-viewer,
#design-viewer.open-viewer{
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(0vh);
}

.box {
  border-radius: 50px;
  background: var(--color-light);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 100%;
}

#viewer h2, #design-viewer h2,
#viewer p, #viewer a,
#design-viewer p, #design-viewer a{
  color: var(--color-dark);
}

#viewer a,
#design-viewer a {
  cursor: pointer;
}

.close-icon {
  position: absolute;
  text-align: right;
  background-color: transparent;
  color: var(--color-dark);
  top: 2em;
  right: 50px;
  z-index: 6;
  border-radius: 50px;
  padding: 10px 12px 2px;
  font-size: 1.2em;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon:focus-visible {
  outline: 4px solid var(--color-dark);
  outline-offset: 4px;
}

#viewer .skills,
#design-viewer .skills{
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
}

#viewer .skills li,
#design-viewer .skills li{
  list-style-type: none;
  padding: .4em 1em .2em;
  margin-right: 1em;
  background-color: var(--color-teal);
  color: var(--color-light);
  border-radius: 50px;
  /* border: 3px solid black; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-attributes{
  display: flex;
  padding-bottom: 1em;
}

.project-link {
  padding: .4em 1em .2em;
}

.project-link:focus-visible {
  border-radius: 50px;
  outline: 4px solid var(--color-dark);
  outline-offset: 4px;
}

#viewer.web .project-info {
  gap: .8em;
  display: flex;
  flex-direction: column;
}

/* Design Viewer */

#design-viewer .nav{
  position: fixed;
  right: 0;
  top: 0;
}

#design-viewer .gallery{
  padding-left: calc(33% + 100px);
  width: 100vw;
  overflow-y: overlay;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  z-index: 5;
}

#design-viewer .gallery img{
  width: 700px;
}

#design-viewer .gallery li:first-child{
  margin-top: 2em;
}

#design-viewer .gallery li {
  border: none;
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: auto;
  display: flex;
}

#design-viewer .project-header{
  display: flex;
  flex-direction: row;
}

#design-viewer .project-data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  background-color: transparent;
  color: var(--color-dark);
  padding: 50px 0;
  height: 100%;
  left: 50px;
  z-index: 4;
}

#design-viewer #description{
  flex-grow: 1;
}

#design-viewer .project-link{
  position: absolute;
  background-color: var(--color-teal);
  color: var(--color-light);
  text-align: right;
  bottom: 2em;
  right: 3em;
  z-index: 6;
  border-radius: 50px;
  padding: .4em 1em .2em;
}

#design-viewer .project-link:hover{
  color: var(--color-light);
  background-color: var(--color-dark);
  transition: background-color ease-in-out .2s;
  text-decoration: none;
}

/* Media Queries */

/* Media Query for Mobile Devices */
@media (max-width: 768px) {

  .close-icon{
    top: 1em;
    right: 1em;
    background-color: var(--color-light);
    border-radius: 50px;
    font-size: 1em;
    padding: 5px 8px 0px;
  }

  .close-icon:focus-visible {
    outline-width: 2px;
  }

  #viewer.web #title{
    text-align: start;
    padding: 0;
    font-size: 1.5em;
  }

  #viewer.web .project-info {
    gap: .8em;
    display: flex;
    flex-direction: column;
  }

  #viewer.web .video-container {
    flex-grow: 1;
  }

  #viewer.web .project-info,
  #viewer.web .video-container {
    padding: 0px 1.5em;
    width: 100%;
  }

  #viewer.web .box{
    width: 100%;
    max-height: 100vh;
  }

  #viewer.web .project-container {
    flex-direction: column;
    padding: 0px;
    margin-top: 3em;
    align-items: center;
    gap: .8em;
  }

  #viewer.web video{
    width: 100%;
  }

  #viewer.web .project-attributes{
    margin-bottom: 3em;
    padding: 0 1.5em;
    display: grid;
    grid-template-areas: "skills skills"
                         "year link";
    height: 25%;
  }

  #viewer.web .project-link{
    text-decoration: underline;
    grid-area: link;
    padding: 0;
    text-align: end;
    align-self: end;
  }

  #viewer.web .project-attributes > p {
    margin: 0;
    grid-area: year;
    align-self: end;
  }

  #viewer.web .skills {
    justify-content: center;
    text-align: center;
    flex-grow: 0;
    padding: 0px;
    width: 100%;
    font-size: .9em;
    grid-area: skills;
    flex-wrap: wrap;
    gap: .2em;
  }

  #viewer.web .skills li:last-child{
    margin-right: 0px;
  }

  /* Design Viewer */

  #design-viewer .project-data {
    height: 40%;
    padding: 4em 1.5em 0;
    width: 100%;
    z-index: 3;
    left: 0;
    gap: .8em;
  }

  #design-viewer .project-data .padding-top {
    padding-top: 0;
  }

  #design-viewer .project-data p {
    margin: 0;
  }

  #design-viewer #title{
    text-align: start;
    padding: 0;
    font-size: 1.5em;
    margin: 0;
  }

  #design-viewer .gallery {
    margin-left: 0px;
    padding-left: 0;
  }

  #design-viewer .gallery li{
    margin-right: 0px;
  }

  #design-viewer .gallery li:first-child{
    margin-top: 66vh;
    background-color: transparent;
  }

  #design-viewer .gallery li:last-child {
    background-color: transparent;
  }

  #design-viewer .gallery li:last-child img{
    border-radius: 0 0 50px 50px;
  }

  #design-viewer .gallery li{
    padding: 0px;
    border-radius: 0px;
    background-color: var(--color-light);
  }

  #design-viewer .gallery img{
    margin-bottom: -.3em;
    width: 100vw;
  }

  #design-viewer .project-link{
    top: .8em;
    bottom: initial;
    right: initial;
    left: 33%;
  }
}
