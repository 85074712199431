/* Web Portfolio */
#web-portfolio {
  background-color: var(--color-blue);
  color: var(--color-light);
  border-radius: 50px 50px 0 0;
}

.web-projects {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
  padding: 20px 0;
}

.web-projects .project{
  width: 650px;
  height: 300px;
  padding: 10px 10px;
  border-radius: 25px;
}

.web-projects .project:focus-visible {
  outline: 4px solid var(--color-yellow);
  outline-offset: -4px;
  z-index: 10;
}

@keyframes scroll{
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-300px * 6 - 30px));
  }
}

.animation-top{
  animation: scroll 60s linear infinite;
}

.animation-down{
  animation: scroll 60s linear infinite reverse -7.5s;
}

.slider {
  width: 60%;
  margin: auto;
  overflow:hidden;
  position: relative;
  height: 100%;
}

.slider-wrap {
		display: flex;
		width: calc(600px * 6);
    height:100%;
	}

.slide{
  width:300px;
  height:100%;
  text-align:center;
}

.projects-cta{
  background-color: #3619FD;
  color: #FFF3E2;
  position: absolute;
  top: 45%;
  right: 33%;
  z-index: 5;
  padding: 2em 3em;
}

.web-projects-mobile{
  display: none;
}
