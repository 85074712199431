.navbar {
	color: var(--color-dark);
	display: flex;
	font-family: var(--font-serif);
	justify-content: space-between;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 10;
	pointer-events: none;
	/* background-color: var(--color-red);*/
}

.navbar > div,
.navbar > nav {
	font-size: 1.3em;
	pointer-events: all
}

.navbar .contrast {
	font-size: 1em;
	border: 0;
	font-family: var(--font-serif);
	background-color: var(--color-red);
	border-radius: 50px;
	padding: .4em .8em .2em;
	color: var(--color-light);
	transition: background-color .2s ease-in-out;
}

.navbar .contrast:hover {
	background-color: var(--color-dark);
}

.navbar .contrast:focus-visible {
	outline: 4px solid var(--color-dark);
	outline-offset: 4px;
}