/* Media Queries */

/* Media Query for Mobile Devices */
@media (max-width: 900px) {
  body {
    font-size: 1.3em;
  }

  .cursor{
    display: none;
  }

  h1, h2{
    font-size: 1.7em;
  }

  .padding {
    padding: 2em 2em;
  }

  .padding-x {
    padding: 0 2em;
  }

  .padding-y {
    padding: 2em 0;
  }

  .padding-bottom {
    padding-bottom: 2em;
  }

  .padding-top {
    padding-top: 2em;
  }

  .width-50,
  .width-33,
  .width-100 {
    width: 100%;
  }

  .d-flex{
    flex-direction: row;
    flex-wrap: wrap;
  }

  .flex-50,
  .flex-33,
  .flex-66 {
    flex-basis: 100%;
  }

  .m-0-padding{
    padding: 0;
  }

  .hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
    -webkit-transform: initial;
    transform: initial;
    z-index: initial;
  }

  /* Navbar */

  .container{
    padding: 2em 2em;
  }

  .navbar > div,
  .navbar > nav {
    font-size: 1em;
    pointer-events: all
  }

  .navbar > div {
    flex: 0;
  }

  /* Intro */

  #intro h1{
    width: 80%;
  }

  /* Web Portfolio */

  #web-portfolio {
    flex-direction: column;
  }

  .web-projects-mobile{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .slider{
    display: none;
  }

  .project-mobile{
    width: 100vw;
    border-radius: 25px;
  }

  /* Design Portfolio */

  #design-portfolio {
    flex-direction: column;
    gap: 10px;
  }

  .design-projects {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .design-projects .project {
    aspect-ratio: 16 / 9;
    object-fit: cover;
    width: 100%;
    height: auto;
  }

  /* About */

  #about {
    flex-direction: column;
  }

  /* Services */

  #services ul li {
    padding: .3em 1em .1em;
  }

  #services .padding {
    padding-bottom: 5em;
  }

  /* Contact */

  #contact {
    height: auto;
    min-height: 50vh;
    padding-top: 0;
    justify-content: space-between;
  }

  .email {
    font-size: 1em;
    height: 80vh;
  }

  /* Footer */

  #footer .footer-laptop{
    display: none;
  }

  #footer .footer-mobile{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
  }

  #footer .footer-mobile ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: .5em;
    padding: 0;
    width: 100%;
    padding: 0 2em;
    min-height: 10vh;
    margin: 0 0 1em;
  }

  #footer .footer-mobile ul li{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
  }

  #footer p {
    margin: 0 0 1.8em;
  }

  .CookieConsent{
    left: 10%;
    width: 66%;
  }
}

/* Vertical tablets */

@media (min-width: 800px) and (max-width: 900px){

}

/* Media Query for Tablets Ipads horizontal mode */
@media (min-width: 900px) and (max-width: 1200px){
  body {
    font-size: 1.3em;
  }

  .cursor{
    display: none;
  }

  .container {
    padding: 2em 4em;
  }

  .padding{
    padding: 3em 4em;
  }

  .padding-x{
    padding: 0em 4em;
  }

  .close-icon{
    right: 4em;
  }

  /* Viewer */

  .project-info{
    width: 20%;
  }

  .video-container{
    width: 80%;
  }

  .video-container video{
    width: 600px;
  }

  /* Design Portfolio */

  .design-projects{
    flex-direction: row;
  }

  #design-portfolio .second-emoji {
    transform: rotate(45deg) translateY(-50px);
    left: 200px;
  }

  .design-projects .project {
    margin-right: -2em;
  }


   /* About */

   .first-photo{
    flex-direction: column-reverse;
  }

  .first-photo .first-photo-img {
    width: 300px;
    z-index: 2;
    transform: translate(-10px, 10px);
  }

  .first-photo-text{
    position: initial;
  }

  .first-photo .flecha {
    left: 190px;
    transform: rotate(-90deg);
    width: 120px;
    position: absolute;
    top: 60px;
    z-index: 3;
  }

  .photos{
    padding: 0em 0em 0em 10em;
  }

  .second-photo{
    /* transform: translate(20px, -10px); */

    transform: none;
    flex-direction: column-reverse;
  }

  .second-photo .second-photo-img {
    align-self: flex-end;
    transform: none;
    position: relative;
    width: 300px;
    z-index: 1;
  }

  .second-photo-text{
    position: initial;
    text-align: right;
  }

  /* Services */

  #services ul{
    padding: 0;
  }

  #services p{
    text-align: center;
    margin-bottom: 2em;
  }

}

/* Media Query for Laptops and Desktops */
@media (min-width: 1200px) {
  /* body {
    color: red;
  } */
}
