#experience {
    background-color: var(--color-light);
    flex-direction: column;
    border-radius: 50px 50px 0 0;
}

#experience .card-container {
    display: flex;
    flex-direction: column;
    column-gap: 0;
    margin: 0;
    padding: 0;
}

#experience .card-container li {
    width: 100%;
    border-radius: 50px 50px 0 0;
    padding: 20px 40px;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--color-light);
    display: flex;
    flex-direction: column;
    margin: 0;
}

#experience .card-container li:nth-child(1) {
    background-color: var(--color-teal);
}

#experience .card-container li:nth-child(2) {
    background-color: var(--color-red);
}

#experience .card-container li:nth-child(3) {
    background-color: var(--color-blue);
}

#experience .card-container li h3 {
    text-align: start;
    width: 100%;
}

#experience .card-container li p {
    display: none;
}