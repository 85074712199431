@import './Intro.css';
@import './Viewer.css';
@import './WebPortfolio.css';
@import './Experience.css';
@import './DesignPortfolio.css';
@import './About.css';
@import './Contact.css';
@import './Services.css';
@import "./Loader.css";
@import "./Navbar.css";

*, *::before, *::after {
    box-sizing: border-box;
}

/* Fonts */

@font-face {
    font-display: swap;
    font-family: 'noto_emoji';
    font-style: normal;
    font-weight: normal;
    src: url('../../public/fonts/noto-emoji-v53-emoji-regular.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'noto_emoji';
    font-style: normal;
    font-weight: 700;
    src: url('../../public/fonts/noto-emoji-v53-emoji-700.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'martian_mono';
    font-style: normal;
    font-weight: normal;
    src: url('../../public/fonts/MartianMono-StdBd.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'martian_mono';
    font-style: normal;
    font-weight: bold;
    src: url('../../public/fonts/MartianMono-StdxBd.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'inria_serif';
	font-style: normal;
	font-weight: normal;
	src: url('../../public/fonts/InriaSerif-Regular.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'inria_serif';
	font-style: normal;
	font-weight: lighter;
	src: url('../../public/fonts/InriaSerif-Light.woff2') format('woff2');
}

/* Font variables */

:root {
	--font-mono: 'martian_mono';
	--font-serif: 'inria_serif';
}

/* Color variables */

:root {
	--color-blue: #3772FF;
	--color-dark: #080708;
	--color-light: #E6E8E6;
	--color-red: #DF2935;
	--color-teal: #28AFB0;
	--color-yellow: #FDCA40;
}

/* Scrollbar */

/*
::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: var(--color-dark);
}

::-webkit-scrollbar-thumb {
	background: var(--color-teal);
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background: var(--color-teal);
}
*/

@keyframes load8 {
	0% {
	transform: rotate(0deg);
	}
	100% {
	transform: rotate(360deg);
	}
}

html {
	background-color: var(--color-yellow);
	color: var(--color-dark);
	scroll-behavior: smooth;
}

h1, h2, h3 {
	font-family: var(--font-mono), Verdana, sans-serif;
}

h1, h2 {
	font-size: 2.4em;
	font-weight: 700;
	margin-top: 0px;
}

h3 {
	font-weight: 400;
}

.emoji {
	font-family: 'noto_emoji', sans-serif;
	font-weight: 700;
	font-size: 4.5em;
}

body {
	cursor: none;
	font-family: var(--font-serif), Verdana, sans-serif;
	font-size: 1.4em;
	line-height: 1.2;
	margin: 0;
	overflow-y: overlay;
	padding: 0;
}

body.no-scroll {
	overflow: hidden;
}

/* Links */

a {
	color: initial;
	cursor: none;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

.container{
	padding: 2em 6em;
}

.height-100 {
	height: 100%;
}

.margin-0 {
	margin: 0;
}

.padding{
	padding: 3em 6em;
}

.padding-x{
	padding: 0em 6em;
}

.padding-y{
	padding: 3em 0em;
}

.padding-y-2{
	padding: 6em 0em;
}

.padding-top{
	padding-top: 3em;
}
.padding-bottom{
	padding-bottom: 3em;
}

.d-flex{
	display: flex;
	height: 100%;
}

.flex-grow {
	flex-grow: 1;
}

.flex-shrink {
	flex-shrink: 1;
}

.flex-66 {
	flex-basis: 66%;
}

.flex-small-half {
	flex-basis: 41.666667%;
}

.flex-big-half {
	flex-basis: 58.333333%;
}

.flex-50 {
	flex-basis: 50%;
}

.flex-33 {
	flex-basis: 33%;
}

.align-items-center{
	align-items: center;
}

.align-items-end{
	align-items: flex-end;
}

.justify-content-between{
	justify-content: space-between;
}

.justify-content-around{
	justify-content: space-around;
}

/* Width rules */

.width-33 {
	width: 33%;
}

.width-50{
	width: 50%;
}

.width-66{
	width: 66%;
}

.width-100{
	width: 100%;
}

/* Align rules */

.text-center{
	text-align: center;
}

.text-right{
	text-align: right;
}

.text-left{
	text-align: left;
}

.position-bottom{
	bottom: 0;
	position: absolute;
}

/* Footer */

#footer .footer-mobile{
	display: none;
}

#footer .footer-laptop{
	display: flex;
}

/* Hover Effects */

.hvr-grow {
	-webkit-transform: perspective(1px) translateZ(0);
	-webkit-transition-duration: 0.3s;
	-webkit-transition-property: transform box-shadow;
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	display: inline-block;
	transform: perspective(1px) translateZ(0);
	transition-duration: 0.3s;
	transition-property: transform box-shadow;
	vertical-align: middle;
	z-index: 0;
}
.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
	z-index: 1;
}

/* Cursor */

.cursor {
	-webkit-font-smoothing: subpixel-antialiased;
	align-items: center;
	backface-visibility: hidden;
	background-color: var(--color-light);
	border-radius: 50%;
	color: var(--color-dark);
	display: flex;
	font-family: 'noto_emoji', sans-serif;
	font-weight: 700;
	height: 35px;
	justify-content: center;
	left: 0;
	pointer-events: none;
	position: fixed;
	text-align: center;
	top: 0;
	width: 35px;
	z-index: 99;
	/*mix-blend-mode: difference;*/
}
